@import "../base/variables-colors";
@import "../base/variables-typography";
@import "../base/variables-breakpoints";
@import "../base/variables";
@import "../base/mixins";

// Footer
//
// This footer is only used in the "takeover" page pattern. It's purpose is actually obscure - when you scroll to the bottom of a page and start updating the page with JS (eg, clicking options in nyteckning elavtal), the page appears to jump up and down. Having the slack in the page with a footer reduces the effect. It's also built so that it anchors to the bottom of the page, even if the page content doesn't reach the bottom of the window.
//
// .Footer - footer to use in "takeover" page pattern
//
// markup:
// <div class="Footer"></div>
//
// Styleguide 2.9

$color-footer-bg: $color-grey0;
$color-footer-fg: #000;
$links-breakpoint-min: 400px;
$links-breakpoint-max: $links-breakpoint-min - 1px;
$footer-font-size-sm: $font-size-xs;
$footer-font-size-lg: $font-size-sm;

.Footer {
    
    &-Inner {
        max-width: $width-xl;
        margin-left: auto;
        margin-right: auto;
        @media print {
            max-width: $print-width;
        }
        &:after {
            content: '';
            display: table;
            height: 0;
            overflow: hidden;
        }
    }

    &-Base {
        margin: $gutter*10 0 $gutter*2;
        padding: 0 $gutter;
        font-size: $footer-font-size-sm;
        page-break-inside: avoid;
        @media (min-width: $xs-and-up) {
            margin-top: $gutter*6;
            padding-left:  calc(#{$gutter} + env(safe-area-inset-left));
            padding-right: calc(#{$gutter} + env(safe-area-inset-right));
        }

        text-align: center;
        @media print {
            border-top: 2px solid $color-line;
            margin-top: $print-margin !important;
            padding-top: $print-margin;
        }
        &-Logos {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            margin: 0 0 1em;
        }
        &-Logo {
            display: block;
            width: 100px;
            margin: 0 2em;
            height: auto;

            @media print {
                filter: invert(100%);
            }

            &--neon {
                width: 100px;
            }

            &-Link {
                display: inline-block;
                vertical-align: bottom;
            }
        }
        &-Text {
            display: block;
            padding: $gutter*4 0 0;
            color: $color-grey5;
            @media print {
                margin: 0;
                padding: 0;
            }
        }
        &-Info {
            display: block;
            padding: $gutter 0 0;
            color: $color-bg-hi2;
            @media print {
                display: none;
            }
        }
    }

    &-Screening {
        margin: 0 0 $gutter*3;
        font-size: $font-size-md2;
        line-height: 1.3em;
        @include typo-serif();
        a {
            @include typo-smallcaps();
        }
    }
}

.Social {
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    opacity: 0.8;
    &-Logo {
        display: block;
        @supports ( animation:none ) {
            opacity: 0;
            animation: fade-in 1000ms forwards;
            animation-delay: 1500ms;
            &--vimeo {
                animation-delay: 1700ms;
            }
        }
        &-Link {
            padding: 15px 10px;
            display: inline-block;
        }
    }
    @media (min-width: $xs-and-up) {
        left: auto;
        right: 15px;
        right: calc(15px + env(safe-area-inset-right));
    }
    @media print {
        display: none;
    }
}


.Bg-Bottom-Left,
.Bg-Bottom-Right1,
.Bg-Bottom-Right2,
.Bg-Bottom-Right3 {
  display: none;
  position: absolute;
  overflow: hidden;
  z-index: -1;
  @media (min-width: $md-and-up) {
      display: block;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0 0;
  }
}

.Bg-Bottom-Left {
    left: 0;
    bottom: 0;
    width: 469px;
    height: 644px;
    opacity: 0.6;
}
.Bg-Bottom-Right1 {
    right: 0px;
    bottom: 390px;
    width: 117px;
    height: 209px;
}
.Bg-Bottom-Right2 {
    right: 0px;
    bottom: 140px;
    width: 178px;
    height: 283px;
}
.Bg-Bottom-Right3 {
    right: 0;
    bottom: 0px;
    width: 315px;
    height: 200px;
}